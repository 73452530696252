$(document).ready(
    function () {

        let reservationAlert = $("#reservation-alert");
        let reservationAlertCountDown = $('#reservation-alert-countdown');
        let reservationExpired = reservationAlert.attr('data-reservation-expired');
        let expirationTimeAlert = parseInt(reservationAlertCountDown.attr("data-remaining-time")) * 1000;

        reservationCountDownAlert(expirationTimeAlert, reservationAlertCountDown);

        function reservationCountDownAlert() {

            let remainingTime = expirationTimeAlert;

            let x = setInterval(function () {

                remainingTime = remainingTime - 1000;
                let minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = ('0' + Math.floor((remainingTime % (1000 * 60)) / 1000)).slice(-2);

                reservationAlertCountDown.text(minutes + ":" + seconds);

                if (remainingTime <= 0) {
                    clearInterval(x);
                    $.ajax({
                        type: 'GET',
                        url: "/ajax/order/cancel_reservation_timeout"
                    })
                        .done(function () {
                            removeDiscountCookie();
                            reservationAlert.text(reservationExpired);

                            if ($('.modal.show').length > 0) {
                                $('.modal.show').modal('hide');
                            }
                        })
                }

            }, 1000);

            return x;

        }

        function removeDiscountCookie() {
            let daysForCookieToExpire = 0;
            let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
            document.cookie = "discount = ;expires=" + cookieExpires + "; path=/; SameSite=strict";
        }

    }
);

