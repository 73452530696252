$(document).ready(
    function () {
        let p = $("body").attr("p");
        if (p === 'admin_organizers' || p === 'dealer_organizers' || p === 'seller_organizers' || p === 'organizer_settings_config') {
            let config_option_ajax_lock = false;

            $(document).on("submit", "#config-option-organizer-form", function (e) {
                e.preventDefault();

                if (!config_option_ajax_lock) {

                    config_option_ajax_lock = true;

                    $.ajax({
                        type: 'POST',
                        url: "/ajax/admin_config_option/update",
                        data: {
                            serialized_post: $(this).serialize()
                        }
                    })
                        .done(function (data) {

                            if (data === true) {
                                popupAlert();
                            }

                        })
                        .fail(function (data) {

                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }

                            if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                                prependAlert('#config-option-organizer-form-button-' + organizerId, data.responseJSON.error_message, 'danger', null, '#config-option-organizer-form-organizer-id-' + organizerId);
                            }

                            config_option_ajax_lock = false;

                        })
                        .always(function () {
                            config_option_ajax_lock = false;
                        });
                }
            });
        }
    }
);