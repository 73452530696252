$(document).ready(function () {
    const body = $("body");
    let p = body.attr("p");
    let a = body.attr("a");
    if (p === 'event' && a === 'show') {
        let getLoginFormModalAjaxLock = false;
        $(document).on('click', '#checkout-user-container #checkout-login-btn', function () {
            const userLoginModal = $('#user-login-modal');
            if (!getLoginFormModalAjaxLock) {
                getLoginFormModalAjaxLock = true;
                if (userLoginModal.length > 0) {
                    if (!userLoginModal.hasClass('show')) {
                        userLoginModal.modal('show');
                    }
                    getLoginFormModalAjaxLock = false;
                } else {
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/user/login_form",
                        data: {
                            organizer_id: body.attr("organizer_id"),
                            origin: 'page-checkout'
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $("#show-event").after(data.html);
                                $('#user-login-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            getLoginFormModalAjaxLock = false;

                        })
                        .always(function () {
                            getLoginFormModalAjaxLock = false;
                        });
                }
            }
        });

        let getNewUserFormModalAjaxLock = false;
        $(document).on('click', '#checkout-user-container #checkout-register-btn', function () {
            const userNewModal = $('#user-new-modal');
            if (!getNewUserFormModalAjaxLock) {
                getNewUserFormModalAjaxLock = true;
                if (userNewModal.length > 0) {
                    if (!userNewModal.hasClass('show')) {
                        userNewModal.modal('show');
                    }
                    getNewUserFormModalAjaxLock = false;
                } else {
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/user/registration_form",
                        data: {
                            organizer_id: body.attr("organizer_id"),
                            origin: 'page-checkout'
                        }
                    })
                        .done(function (data) {
                            if (data.html !== undefined && data.html !== null) {
                                $("#show-event").after(data.html);
                                $('#user-new-modal').modal('show');
                            }
                        })
                        .fail(function (data) {
                            if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                                document.location.href = data.responseJSON.redirect_url;
                            }
                            getNewUserFormModalAjaxLock = false;

                        })
                        .always(function () {
                            getNewUserFormModalAjaxLock = false;
                        });
                }
            }
        });
    }
});