$(document).ready(function () {
    function activateSelect2() {
        if ($('#checkout-customer-missing-fields select[name=country]:not(.select2-hidden-accessible)').length > 0) {
            $('#checkout-customer-missing-fields select[name=country]').select2({
                theme: 'bootstrap4',
                placeholder: ""
            });
        }
    }

    let userLoginAjaxLock = false;
    if (!userLoginAjaxLock) {
        userLoginAjaxLock = true;
        $(document).on("submit", "#user_login_form", function () {
            const isFromCheckout = ($('#user-login-modal.page-checkout.show').length > 0);
            $.ajax({
                type: 'POST',
                url: "/ajax/user/login",
                data: {
                    serialized_post: $(this).serialize(),
                    origin: (isFromCheckout) ? 'page-checkout' : '',
                    organizer_id: $("body").attr("organizer_id")
                }
            })
                .done(function (data) {
                    userLoginAjaxLock = false;

                    if (isFromCheckout) {
                        const checkoutUserCointainer = $('#checkout-user-container');
                        if (data.html !== undefined) {
                            checkoutUserCointainer.after(data.html);
                            activateSelect2();
                        }
                        checkoutUserCointainer.remove();

                        if (data.user !== undefined) {
                            const firstNameInput = $("#order-form input[name='first_name']");
                            const lastameInput = $("#order-form input[name='last_name']");
                            const emailInput = $("#order-form input[name='email']");
                            const phoneNumberInput = $("#order-form input[name='phone_number']");
                            if (firstNameInput.length > 0 && data.user.first_name !== undefined && data.user.first_name !== '') {
                                firstNameInput.val(data.user.first_name);
                            }
                            if (lastameInput.length > 0 && data.user.last_name !== undefined && data.user.last_name !== '') {
                                lastameInput.val(data.user.last_name);
                            }
                            if (emailInput.length > 0 && data.user.email !== undefined && data.user.email !== '') {
                                emailInput.val(data.user.email);
                            }
                            if (phoneNumberInput.length > 0 && data.user.phone_number !== undefined && data.user.phone_number !== '') {
                                phoneNumberInput.val(data.user.phone_number);
                            }
                        }

                        $('#user-login-modal').modal('hide');
                    } else {
                        if (data.redirect_url !== undefined && data.redirect_url !== null) {
                            document.location.href = data.redirect_url;
                        }

                        if (data.isAdmin !== undefined && data.isAdmin === true) {
                            document.location.href = "/admin/sales";
                        } else if (data.isOrganizer !== undefined && data.isOrganizer === true) {
                            document.location.href = "/organizer";
                        } else if (data.isSeller !== undefined && data.isSeller === true) {
                            document.location.href = "/seller/sales";
                        } else if (data.isDealer !== undefined && data.isDealer === true) {
                            document.location.href = "/dealer/sales";
                        } else if (data.isCustomer !== undefined && data.isCustomer === true) {
                            document.location.href = "/user/tickets";
                        }
                    }
                })
                .fail(function (data) {
                    userLoginAjaxLock = false;
                    if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                        prependAlert('#div_user_login_button', data.responseJSON.error_message, 'danger', null, '#user_login_form')
                    }
                });
            return false;
        });
    }
});