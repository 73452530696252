$(document).ready(
    function () {

        let p = $("body").attr("p");
        if (p == 'organizer_events'
            || p == 'organizer_settings'
            || p == 'organizer_settings_users'
            || p == 'organizer_settings_term'
            || p == 'organizer_events'
            || p == 'organizer_event'
            || p == 'organizer_payout_reports'
            || p == 'organizer_event_sales'
            || p == 'organizer_event_sales_event_ticket_category'
            || p == 'organizer_event_subevent_sales'
            || p == 'organizer_event_subevent_sales_event_ticket_category'
            || p == 'organizer_event_subevent_sales_timeslots'
            || p == 'organizer_event_subevent_customers'
            || p == 'organizer_event_subevent_entered_customers'
            || p == 'organizer_event_subevent'
            || p == 'organizer_event_ticket_category'
            || p == 'organizer_event_customers'
            || p == 'organizer_event_customer_search'
            || p == 'organizer_recurring_event_entered_customers'
            || p == 'organizer_event_period_tickets'
            || p == 'organizer_event_entered_customers'
            || p == 'organizer_event_ticket_category'
            || p == 'visit_tracker_location'
            || p == 'visit_tracker_visitor'
            || p == 'dealer_sales'
            || p == 'dealer_organizers'
            || p == 'dealer_sellers'
            || p == 'seller_organizer'
            || p == 'seller_organizers'
            || p == 'seller_fees'
            || p == 'seller_sales'
            || p == 'organizer_dashboard'
            || p == 'organizer_customers') {

            $("#organizerAdminMenuLeftToggleButton").click(function () {
                if ($(this).text() == 'menu') {
                    $(this).text('close');
                    removeLeftMenuCollapseCookie();
                    openLeftMenu($(this));
                } else {
                    $(this).text('menu');
                    addLeftMenuCollapseCookie();
                    closeLeftMenu($(this));
                }
            });

            function closeLeftMenu(button) {
                $("#admin-content").addClass('collapsed');
                button.removeClass('ml-2');
                button.closest("nav.sidebar").removeClass('col-2');
                button.closest("nav.sidebar").removeClass('col-xl-1');
                button.closest(".sidebar-sticky").find("ul").addClass('d-none');
                $('div#admin-content-frame').removeClass('col-md-10');
                $('div#admin-content-frame').removeClass('col-xl-11');
                $('div#admin-content-frame').addClass('col-md-12');
            }

            function openLeftMenu(button) {
                $("#admin-content").removeClass('collapsed');
                button.addClass('ml-2');
                button.closest("nav.sidebar").addClass('col-2');
                button.closest("nav.sidebar").addClass('col-xl-1');
                button.closest(".sidebar-sticky").find("ul").removeClass('d-none');
                $('div#admin-content-frame').removeClass('col-md-12');
                $('div#admin-content-frame').addClass('col-md-10');
                $('div#admin-content-frame').addClass('col-xl-11');
            }

            function removeLeftMenuCollapseCookie() {
                let daysForCookieToExpire = 0;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "collapse_left_menu = ;expires=" + cookieExpires + "; path=/; SameSite=strict";
            }

            function addLeftMenuCollapseCookie() {
                let daysForCookieToExpire = 7;
                let cookieExpires = new Date(new Date().getTime() + daysForCookieToExpire * 1000 * 60 * 60 * 24);
                document.cookie = "collapse_left_menu = true;expires=" + cookieExpires + "; path=/; SameSite=strict";
            }

        }

    });