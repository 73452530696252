$(document).ready(function () {
    let p = $("body").attr("p");
    if (p == 'organizer_customers') {
        let filterEventSelect2Activated = false;

        $('#customers-filters-toggle-btn').click(function () {
            let filters = $('#organizer-customers-filters');
            if (filters.hasClass('d-none')) {
                filters.removeClass('d-none');
                if (!filterEventSelect2Activated) {
                    $('#organizer-customers-filters select#event_id').select2({
                        theme: 'bootstrap4',
                        placeholder: ""
                    });
                    filterEventSelect2Activated = true;
                }
            } else {
                filters.addClass('d-none');
            }
        });

        let getCategoriesAjaxLock = false;
        $(document).on('change', '#customers-filters-form #event_id', function () {
            let eventId = $(this).val();
            $('#customers-filters-form #event_ticket_category_id').empty();
            if (eventId == 0) {
                $('#customers-filters-form #event_ticket_category_id option[value=0]').prop('selected', true);
                if (!$('#customers-filters-form #customers-filters-category-container').hasClass('d-none')) {
                    $('#customers-filters-form #customers-filters-category-container').addClass('d-none');
                }
            } else {
                $('#customers-filters-form #customers-filters-category-container').removeClass('d-none');
                if (!getCategoriesAjaxLock) {
                    getCategoriesAjaxLock = true;
                    $.ajax({
                        type: 'POST',
                        url: "/ajax/organizer_customers/get_event_ticket_categories",
                        data: {
                            event_id: eventId
                        }
                    })
                        .done(function (data) {
                            if (data.categories !== undefined) {
                                $.each(data.categories, function (i, item) {
                                    $('#customers-filters-form #event_ticket_category_id').append($('<option>', {
                                        value: item.value,
                                        text: item.text
                                    }));
                                });

                                $('#organizer-customers-filters select#event_ticket_category_id').select2({
                                    theme: 'bootstrap4',
                                    placeholder: ""
                                });
                            }
                            getCategoriesAjaxLock = false;
                        })
                        .fail(function (data) {
                            popupAlert(500, 'danger');
                            getCategoriesAjaxLock = false;
                        });
                }
            }
        });

        let customersFiltersForm = $('#customers-filters-form');

        function getFilterFilenamePart() {
            let filterFilenamePart = '';

            let eventId = $('#event_id option:selected', customersFiltersForm).val();
            if (!isNaN(eventId) && eventId > 0) {
                filterFilenamePart += '_event-' + $('#event_id option:selected', customersFiltersForm).text();

                let eventTicketCategoryId = $('#event_ticket_category_id option:selected', customersFiltersForm).val();
                if (!isNaN(eventTicketCategoryId) && eventTicketCategoryId > 0) {
                    filterFilenamePart += '_ticket-category-' + $('#event_ticket_category_id option:selected', customersFiltersForm).text();
                }
            }

            let zipFrom = $('#zip_from', customersFiltersForm).val();
            let zipTo = $('#zip_to', customersFiltersForm).val();
            if (zipFrom !== '' || zipTo !== '') {
                filterFilenamePart += '_zip-';
                if (zipFrom !== '') {
                    filterFilenamePart += 'from-' + zipFrom;
                }
                if (zipTo !== '') {
                    if (zipFrom !== '') {
                        filterFilenamePart += '-';
                    }
                    filterFilenamePart += 'to-' + zipTo;
                }
            }

            let ageFrom = $('#age_from', customersFiltersForm).val();
            let ageTo = $('#age_to', customersFiltersForm).val();
            if (ageFrom !== '' || ageTo !== '') {
                filterFilenamePart += '_age-';
                if (ageFrom !== '') {
                    filterFilenamePart += 'from-' + ageFrom;
                }
                if (ageTo !== '') {
                    if (ageFrom !== '') {
                        filterFilenamePart += '-';
                    }
                    filterFilenamePart += 'to-' + ageTo;
                }
            }

            let gender = $('#gender option:selected', customersFiltersForm).val();
            if (gender >= 0 && gender <= 1) {
                filterFilenamePart += '_gender-' + $('#gender option:selected', customersFiltersForm).text();
            }
            return '&filter_filename_part=' + encodeURIComponent(filterFilenamePart);
        }


        let params = {}

        $(document).on('submit', '#customers-filters-form', function (e) {
            e.preventDefault();
            params.search_string = $('#search_string', customersFiltersForm).val();
            params.event_id = $('#event_id option:selected', customersFiltersForm).val();
            params.event_ticket_category_id = $('#event_ticket_category_id option:selected', customersFiltersForm).val();
            params.zip_from = $('#zip_from', customersFiltersForm).val();
            params.zip_to = $('#zip_to', customersFiltersForm).val();
            params.age_from = $('#age_from', customersFiltersForm).val();
            params.age_to = $('#age_to', customersFiltersForm).val();
            params.gender = $('#gender option:selected', customersFiltersForm).val();
            params.page = 1;

            getCustomers(params);
            popupAlert();

            return false;
        });

        $(document).on('click', '#organizer-customers-download-btn, #organizer-customers-emails-download-btn', function () {
            let formElements = $('#customers-filters-form').serializeArray();
            let urlParams = '';

            if ($(this).attr('id') === 'organizer-customers-emails-download-btn') {
                urlParams += '&type=emails';
            }

            $.each(formElements, function (i, field) {
                if (urlParams !== '') {
                    urlParams += '&';
                }
                urlParams += field.name + '=' + encodeURIComponent(field.value);
            });

            if (urlParams !== '') {
                urlParams = '?' + urlParams;
                urlParams += getFilterFilenamePart();
            }

            document.location.href = $(this).data('url') + urlParams;
        });

        $(document).on("click", ".pagination a", function (e) {
            e.preventDefault();
            params.page = parseInt($(this).attr("href").substring(6));
            getCustomers(params);
        });

        $(document).on("click", "#organizer-customers-table .sort", function (e) {
            e.preventDefault();
            params.order_by = $(this).data('order-by');

            if ($(this).hasClass('sort-active')) {
                params.order_by_direction = ($(this).data('order-direction') === 'ASC') ? 'DESC' : 'ASC';
                $(this).data('order-direction', params.order_by_direction);
                if (params.order_by_direction === 'ASC') {
                    $('.material-icons', $(this)).addClass('material-icons-asc');
                } else {
                    $('.material-icons', $(this)).removeClass('material-icons-asc');
                }
            } else {
                let lastSortedByColumn = $('#organizer-customers-table .sort.sort-active');
                $('.material-icons', lastSortedByColumn).remove();
                lastSortedByColumn.removeClass('sort-active');
                $(this).addClass('sort-active');
                $(this).prepend('<i class="material-icons material-icons-asc">sort</i>');
                params.order_by_direction = $(this).data('order-direction');
            }

            getCustomers(params);
        });

        let getCustomersAjaxLock = false;

        function getCustomers(params) {
            if (!getCustomersAjaxLock) {
                getCustomersAjaxLock = true;
                $.ajax({
                    type: 'POST',
                    url: "/ajax/organizer_customers/list",
                    data: params
                })
                    .done(function (data) {
                        if (data.body !== undefined) {
                            $('#organizer-customers-table tbody').html(data.body);
                        }
                        $('.pagination').remove();
                        if (data.pagination !== undefined && data.pagination !== null && data.pagination !== '') {
                            $('#organizer-customers-card-box').after(data.pagination);
                        }
                        getCustomersAjaxLock = false;
                    })
                    .fail(function (data) {
                        getCustomersAjaxLock = false;
                        if (data.responseJSON.redirect_url !== undefined && data.responseJSON.redirect_url !== null) {
                            document.location.href = data.responseJSON.redirect_url;
                        }
                    });
            }
        }

        getCustomers(params);
    }
});





