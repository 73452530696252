$(document).ready(function () {
    let p = $("body").attr("p");
    let a = $("body").attr("a");
    if ((p == 'user' && a == 'new') || (p == 'event' && a == 'show')) {
        function activateSelect2() {
            if ($('#checkout-customer-missing-fields select[name=country]:not(.select2-hidden-accessible)').length > 0) {
                $('#checkout-customer-missing-fields select[name=country]').select2({
                    theme: 'bootstrap4',
                    placeholder: ""
                });
            }
        }

        let userNewAjaxLock = false;
        $(document).on("submit", "#user_profile_form", function () {
            if (!userNewAjaxLock) {
                userNewAjaxLock = true;
                const isFromCheckout = ($('#user-new-modal.page-checkout.show').length > 0);

                $.ajax({
                    type: 'POST',
                    url: "/ajax/user/new",
                    data: {
                        serialized_post: $(this).serialize(),
                        origin: (isFromCheckout) ? 'page-checkout' : '',
                        organizer_id: $("body").attr("organizer_id")
                    }
                })
                    .done(function (data) {
                        if (isFromCheckout) {
                            const checkoutUserCointainer = $('#checkout-user-container');
                            if (data.html !== undefined) {
                                checkoutUserCointainer.after(data.html);
                                activateSelect2();
                            }
                            checkoutUserCointainer.remove();

                            if (data.user !== undefined) {
                                const firstNameInput = $("#order-form input[name='first_name']");
                                const lastameInput = $("#order-form input[name='last_name']");
                                const emailInput = $("#order-form input[name='email']");
                                const phoneNumberInput = $("#order-form input[name='phone_number']");
                                if (firstNameInput.length > 0 && data.user.first_name !== undefined && data.user.first_name !== '') {
                                    firstNameInput.val(data.user.first_name);
                                }
                                if (lastameInput.length > 0 && data.user.last_name !== undefined && data.user.last_name !== '') {
                                    lastameInput.val(data.user.last_name);
                                }
                                if (emailInput.length > 0 && data.user.email !== undefined && data.user.email !== '') {
                                    emailInput.val(data.user.email);
                                }
                                if (phoneNumberInput.length > 0 && data.user.phone_number !== undefined && data.user.phone_number !== '') {
                                    phoneNumberInput.val(data.user.phone_number);
                                }
                            }

                            $('#user-new-modal').modal('hide');
                        } else {
                            prependAlert('#user_profile_container', data.message, 'success');
                            $('#user_profile_form').remove();
                            setTimeout(function () {
                                document.location.href = "/login";
                            }, 3000);
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_user_register_button', data.responseJSON.error_message, 'danger', null, '#user_profile_form')
                        }
                        userNewAjaxLock = false;
                    })
                    .always(function () {
                        userNewAjaxLock = false;
                    });
            }
            return false;
        });
    } else if (p == 'user' && a == 'profile') {
        $('select[name=country]').select2({
            theme: 'bootstrap4',
            placeholder: ""
        });

        let userEditAjaxLock = false;
        $(document).on("submit", "#user_profile_form", function () {
            if (!userEditAjaxLock) {
                userEditAjaxLock = true;

                $.ajax({
                    type: 'POST',
                    url: "/ajax/user/profile",
                    data: {
                        serialized_post: $(this).serialize()
                    }
                })
                    .done(function (data) {
                        if (data.confirm_needed !== undefined && data.confirm_needed == 1 && data.message !== undefined) {
                            if (confirm(data.message)) {
                                userEditAjaxLock = false;
                                $('#user_profile_form #confirm').val('1');
                                $('#user_profile_form').submit();
                            } else {
                                document.location.reload();
                            }
                        } else {
                            popupAlert();
                        }
                    })
                    .fail(function (data) {
                        if (data.responseJSON.error_message !== undefined && data.responseJSON.error_message !== null) {
                            prependAlert('#div_user_register_button', data.responseJSON.error_message, 'danger', null, '#user_profile_form')
                        }
                        userEditAjaxLock = false;
                    })
                    .always(function () {
                        userEditAjaxLock = false;
                    });
            }
            return false;
        });
    }
});